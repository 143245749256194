
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(../../../assets/Poppins-Regular.ttf) format('opentype');
}
  body{
    font-family: 'Poppins';
  }
  .mystyle{
    font-family: 'Poppins';
  }
  .carfooter{
    font-family: 'Poppins';
    font-style: Bold;
    background-color: black;
    
  }