@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(../../../assets/Poppins-Regular.ttf) format('opentype');
}
  body{
    font-family: 'Poppins'!important;
    color:#3B5FB7;
  }
.MuiInputBase-input {
  font-family: 'Poppins' !important;
  color:#3B5FB7 !important;
  margin-top: 0px;
  margin-bottom: 0px !important;

}
.MuiFormLabel-root {
  font-family: 'Poppins' !important;
  color:#3B5FB7 !important;
  margin-top: 0px;
  margin-bottom: 0px !important; 
}
.MuiMenuItem-root {
  font-family: 'Poppins' !important;
  color:#3B5FB7 !important;  
}
.button{
  border-radius:30px 30px;
text-align:center;
background-color: #6bbb4c;
color: white;
padding: 6px 40px;
align-content:center;
margin-top:10px;
font-family: 'Poppins' !important;
/* justify:"center"   */
}
