@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(../assets/Poppins-Regular.ttf) format("opentype");
}
body {
  font-family: "Poppins";
}
.mystyle {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Poppins";
  font-size: "16px";
  /* margin-left:10px;  */

  color: #3b5fb7;
}

/* .carfooter{ */
/* margin-top:0px;
    margin-bottom:0px;
    font-family: 'Poppins'; */
/* font-style: Bold;
    background-color: black; */

/* } */
.Test1 {
  margin-top: 0px;
  margin-bottom: 5px;
  font-family: "Poppins";
  color: #26a69a;
  text-align: center;
}
.Test2 {
  margin-top: 0px;
  margin-bottom: 5px;
  color: #3b5fb7;
  text-align: center;
}
.Test3 {
  margin-top: 0px;
  margin-bottom: 5px;
  font-family: "Poppins";
  color: #69be47;
  text-align: center;
}
.Sessions {
  margin-top: 0px;
  margin-bottom: 5px;
  font-family: "Poppins";
  color: #69be47;
}

.cardtext {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Poppins";
  color: #00897a;
  text-align: center;
  /* text-align:"center", */
  padding: 0px;
  margin-top: 4px;
  padding-bottom: 0px;
  /* padding:theme.spacing(0),
    marginTop:theme.spacing(4),
    paddingBottom:theme.spacing(0) , */
  background-color: #e0f2f1;
}
.cardicon1 {
  margin-top: 2px;
  /* margin-Top:theme.spacing(2), */
  font-size: 50px;
  font-family: "Poppins";
}
.cardtext1 {
  font-family: "Poppins";
  /* color:#3b62af; */
  text-align: center;
  padding: 0px;
  margin-top: 4px;
  padding-bottom: 0px;
  background-color: #e8eaf6;

  color: #3b5fb7;
  /* padding:theme.spacing(0),
    marginTop:theme.spacing(4),
    paddingBottom:theme.spacing(0) */
}
.secondsection {
  font-size: 12px;
  font-family: "Poppins";
  margin-bottom: 0px;
  margin-top: 0px;

  color: #3b5fb7;
}

.cardnumbers {
  font-family: "Poppins";
  margin-bottom: 0px;
  margin-top: 20px;

  color: #3b5fb7;
}
.maincard {
  color: "#3b62af";
  font-family: "Poppins";
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: -10px;
  color: #3b5fb7;
}
.cardfooter {
  margin-bottom: 0px;
  margin-top: 0px;
  background-color: #01887a;
  color: white;
  padding: 2px;
  font-family: "Poppins";
  /* backgroundColor:"#01887a",
    padding:theme.spacing(0),
    color:"white" */
}
.cardfooter1 {
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: "Poppins";
  background-color: #303f9f;
  color: white;
  padding: 2px;
}
.InsidebackCLR1 {
  padding-bottom: 0px;
  padding: 0px;
  font-family: "Poppins";

  color: #3b5fb7;
}
.specification {
  font-family: "Poppins";
  font-weight: 600;
  color: #69be47;
}
image {
  color: #3b5fb7;
  padding: 0px;
  font-family: "Poppins";
}
.image1 {
  color: #3b5fb7;
  padding: 0px;
  padding-bottom: 0px;
  font-family: "Poppins";
}
.tabletext {
  margin-bottom: 0px;
  margin-top: 2px;
  /* color:#3B5FB7; */
  font-size: 12px;
  color: #69be47;
  font-family: "Poppins";
}
.tabletext1 {
  color: #3b5fb7;
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 10px;
  color: "#3b62af";
  font-family: "Poppins";
}
.img {
  width: 100%;
}
.table {
  color: #3b5fb7;
  padding-left: 10px;
  font-family: "Poppins";
  /* paddingLeft:theme.spacing(1) */
}
.secondsection {
  color: #3b5fb7;
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 12px;
  font-family: "Poppins";
}
.square1 {
  border-radius: 10%;
  background-color: #69be47;
}
.gallerybutton {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  /* color:#3B5FB7; */
  background-color: gray;
  border-radius: 10px;
  font-size: 10px;
  color: white;
  text-align: center;
  font-family: "Poppins";
  padding: 4px;
}
.CardButton {
  padding: 4px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  /* color:#3B5FB7; */
  background-color: #69be47;
  border-radius: 10px;
  font-size: 10px;
  color: white;
  text-align: center;
  font-family: "Poppins";
}
.cardbutton {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  /* color:#3B5FB7; */
  background-color: #69be47;
  border-radius: 10px;
  font-size: 10px;
  color: white;
  text-align: center;
  font-family: "Poppins";
}
.cardbutton1 {
  margin-top: 0px !important;
  /* color:#3B5FB7; */
  background-color: #d34d7a;
  border-radius: 10px;
  font-size: 10px;
  color: white;
  text-align: center;
  font-family: "Poppins";
}
.cardbutton2 {
  margin-top: 0px !important;
  /* color:#3B5FB7; */
  background-color: #43a5f8;
  border-radius: 10px;
  font-size: 10px;
  color: "white" !important;
  text-align: center;
  font-family: "Poppins";
}
.cardbutton3 {
  margin-top: 0px !important;
  /* color:#3B5FB7; */
  background-color: #2aa497;
  border-radius: 10px;
  font-size: 10px;
  color: "white" !important;
  text-align: center;
  font-family: "Poppins";
}
.count {
  margin-bottom: 0px;
  margin-top: 0px !important;
  /* text-align:end !important; */
  font-size: 13px;
  font-family: "Poppins";
  /* text-align: end !important;
  justify-content: center; */
  align-items: flex-end !important;
  color: #3b5fb7;
}
.impname {
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: "Poppins";

  color: #3b5fb7;
}
.calender {
  color: #3b5fb7;
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: "Poppins";
  display: flex;
  justify-content: space-around;
  text-align: "center";
  cursor: pointer;
}
.calendertext {
  color: #3b5fb7;
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: "Poppins";
  justify-content: space-around;
  font-family: "Poppins";
}
.calendertext1 {
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: "Poppins";
  /* color:#3b62af; */
  font-family: "Poppins";

  color: #3b5fb7;
}
.dot {
  height: "5px";
  width: "5px";
  background-color: "#69be47";
  border-radius: "50%";
  display: " inline-block";
}
.Cardfooter {
  color: #3b5fb7;
  margin-top: 10px;
  font-family: "Poppins";
  /* marginTop:theme.spacing(1), */
}
.tabletext2 {
  color: #3b5fb7;
  text-align: end;
  font-family: "Poppins";
  /* paddingRight:theme.spacing(2) */
  padding-right: 20px;
  font-size: 10px;
}
.playicon {
  font-size: 12px;
}
.month {
  /* marginTop:theme.spacing(1), */
  text-align: center;
  margin-top: 10px;
  font-family: "Poppins";

  color: #3b5fb7;
}
.monthtext {
  color: #3b5fb7;
  align-items: center;
  font-family: "Poppins";
}
.Date {
  color: black;
  font-size: 12px;
  font-weight: 600;
}
.pagenation1 {
  color: #3b5fb7;
  display: flex;
  font-family: "Poppins";
}
.pagenation {
  display: flex;
  justify-content: space-around;
  font-family: "Poppins";
}
.grid {
  display: flex;
  justify-content: space-around;
  font-family: "Poppins";
}
.box {
  border: 1px solid gray;
  border-radius: 30px;
  align-items: center;
  display: flex;
  padding: 5px;
}
.box > input {
  flex: 1;
  border: none;
  background: none;
  outline: none;
  padding-left: 8px;
  color: #3b5fb7 !important;
}
::placeholder {
  color: #3b5fb7;
}
.swal-footer {
  text-align: center;
}
.swal-button {
  background-color: #6bbb4c;
  border-radius: 26px;
  padding: 11px 29px;
}
.swal-text {
  color: #3b5fb7;
}
.swal-icon--success__ring {
  border: 4px solid #6bbb4c;
}
.cardmiddle {
  text-align: center;
  color: #6bbb4c;
  margin-top: 20px;
}
.MuiFormControl-marginNormal {
  margin-top: 0px;
  margin-bottom: 5px;
}
.swal-modal {
  width: 400px;
}
.MuiTypography-colorPrimary {
  /* color: #536DFE; */
  color: white !important;
}
.MuiTypography-body1 {
  font-size: 15px !important;
}
.dot {
  height: 6px;
  width: 6px;
  background-color:#6bbb4c;
  border-radius: 50%;
  display: inline-block;
}

@media screen and (max-width: 540px) {
  .mainimg {
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
