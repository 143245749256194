/* .btn {
    color: #fff;
    background-color: #536DFE;
    padding: 11px 50px;
    border-radius: 50px;
    margin-top: 15px;
} */
.days{
    
display: flex;
margin: auto;
justify-content: space-around;

}
.daybtn{
    background-color: red;
    cursor: pointer;
    padding: 5;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    
    text-align: center;
}
#style  {
    background-color: red;
}

.Grid:active  {     
    background:yellow;    
}