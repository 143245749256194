.google{
    width: 100% !important;
/* border: 1px solid red !important; */
border-radius: 50px !important;
box-shadow: none !important;
background-color: #FFFFFF  !important;
color: #202020 !important;
padding: 3px 15px !important;
box-shadow: 0px 3px 6px #00000029 !important;
border: 0.5px solid #E9E9E9 !important;
border-radius: 14px !important;
opacity: 1 !important;

}

.google div {
    border-radius: 50px !important;
    background: none !important;
    padding: 0px !important;
}

.google span{
    padding: 0px !important;  
}